export default {
  data () {
    return {
      reloadData: false
    }
  },
  props: {
    titleStatus: { default: false, type: Boolean }
  },
  methods: {
    search (text) {
      this.filter.search = text.trim()
      this.reloadData = true
      setTimeout(() => { this.reloadData = false }, 500)
    }
  }
}
