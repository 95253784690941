import GeneralFilter from '../../../../Model/GeneralFilter'

export default class ClientTransactionFilter extends GeneralFilter {
  constructor () {
    super()
    this.setInitialValue()
  }

  setInitialValue () {
    this.setFilterInitialValue()
    this.predictionId = ''
    this.user_id = ''
  }
}
