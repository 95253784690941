<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('clientTransactions.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/user-transaction'"
        :reloadData="reloadData"
        :params="filter"
        :createStatus="false"
        :excelFields="excelFields"
        excelName="clientTransactions"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import ClientTransactionFilter from "../models/ClientTransactionFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new ClientTransactionFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        {
          key: "transaction_value",
          label: this.$t("clientTransactions.transactionValue"),
          sortable: true,
        },
        {
          key: "old_credit",
          label: this.$t("clientTransactions.oldCredit"),
          sortable: true,
        },
        {
          key: "credit_expire_date",
          label: this.$t("clientTransactions.creditExpireDate"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "transaction_type",
          label: this.$t("clientTransactions.transactionType"),
          sortable: true,
        },
        {
          key: "source_type",
          label: this.$t("clientTransactions.sourceType"),
          sortable: true,
        },
        {
          key: "source_id",
          label: this.$t("clientTransactions.sourceId"),
          sortable: true,
        },
        {
          key: "direction",
          label: this.$t("clientTransactions.direction"),
          sortable: true,
        },
        {
          key: "valid_after",
          label: this.$t("clientTransactions.validAfter"),
          sortable: true,
        },
        {
          key: "userWallet.name",
          label: this.$t("clientWallets.item"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "transactions.clientTransactionView",
              routeParams: ["id"],
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("clientTransactions.transactionValue")]: "transaction_value",
        [this.$t("clientTransactions.oldCredit")]: "old_credit",
        [this.$t("clientTransactions.creditExpireDate")]: "credit_expire_date",
        [this.$t("clientTransactions.transactionType")]: "transaction_type",
        [this.$t("clientTransactions.sourceType")]: "source_type",
        [this.$t("clientTransactions.sourceId")]: "source_id",
        [this.$t("clientTransactions.direction")]: "direction",
        [this.$t("clientTransactions.validAfter")]: "valid_after",
        [this.$t("clientWallets.item")]: "userWallet.name",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  props: {
    predictionId: { default: "" },
    userId: { default: "" },
  },
  mounted() {
    core.index();
  },
  created() {
    this.filter.predictionId = this.predictionId;
    this.filter.user_id = this.userId;
  },
};
</script>
